import styled from 'styled-components';

export const Container = styled.div`
  padding: 80px 60px;
  background: #53565A;
  @media (max-width: 1000px) {
    padding: 70px 30px;
  }
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
    margin: 0 auto;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 40px;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  grid-gap: 20px;
  @media (max-width: 1000px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));


  }
`;

export const Link = styled.a`
  color: #fff;
  margin-bottom: 20px;
  font-size: 18px;
  text-decoration: none;
  text-align: justify;
  &:hover {
    color: #3CDBC0;
    transform: scale(1.03);
    transition-duration: 1s;
    transition-timing-function: ease-out;
  }
`;

export const Title = styled.p`
  font-size: 22px;
  color: #3CDBC0;
  margin-bottom: 30px;
  font-weight: bold;
`;
