import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FaAlignCenter } from "react-icons/fa";
import logo from "../images/logo.png";

export default class Navbar extends Component {

state = {
    isOpen: false
};
handleToggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
};
render() {
    return (
    <nav className="navbar">
        <div className="nav-center">
        <div className="nav-header">
            <Link to="/">
                <img src={logo} alt="Nuovi Propiedades"/>
            </Link>
            <button
            type="button"
            className="nav-btn"
            onClick={this.handleToggle}
            >
            <FaAlignCenter className="nav-icon" />
            </button>
        </div>
        <ul className={this.state.isOpen ? "nav-links show-nav" : "nav-links"}>
            <li>
                <Link to="/">Home</Link>
            </li>
            <li>
                <Link to="/propiedades">Propiedades</Link>
            </li>
            <li>
                <Link to="/about">Nuovi</Link>
            </li>
            <li>
                <Link to="/contacto">Contacto</Link>
            </li>
        </ul>
        </div>
    </nav>
    );
}
}
