import React from 'react';
import {Link} from 'react-router-dom';
import defaultImg from '../images/room-1.jpeg';
import Fade from 'react-reveal/Fade';
import PropTypes from 'prop-types';

function Propiedad ({property}){

    const{name, slug, images, zone, ref, category, capacity} = property;

    return (
        <div>
        <Fade bottom cascade>
        <article className="property">
            <div className="img-container">
            
                <img src={images[0] || defaultImg} alt="single property" />
                <div className="price-top">
                    <h6>Amb.{capacity}</h6>
                    <p>{zone}</p>
                    <h6>ref:{ref}</h6>
                </div>
                <Link to={`/propiedades/${slug}`} className="btn-primary property-link">
                    Ver Más
                </Link>
                
            </div>
                <p className="property-info">{name} - {category}</p>
        </article>
        </Fade>
    </div>
    );
};
Propiedad.propTypes = {
    property: PropTypes.shape({
    name: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    ref: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(PropTypes.string).isRequired,
    price: PropTypes.number.isRequired,
    capacity: PropTypes.number.isRequired,
    zone: PropTypes.string.isRequired
  })
};

export default Propiedad;
