import React from 'react';

export default function Banner({children, title, title1, subtitle1, subtitle2, title2, subtitle}) {
    return (
        <div className="banner">  
            <h1>{title}</h1>
            <h3>{title1}</h3>
            <h3>{subtitle1}</h3>
            <h3>{subtitle2}</h3>
            <div></div>
            <h2>{title2}</h2>
            <p>{subtitle}</p>
            {children}
        </div>
    )
}