import React, { Component } from 'react';
import {PropContext} from '../context';
import Loading from './Loading';
import Propiedad from './Propiedad';
import Title from './Title';


export default class FeaturedProps extends Component {
    static contextType = PropContext;
    render() {
        let {loading, featuredProperties : properties} = this.context;
        
        properties = properties.map(property => {
            return <Propiedad key={property.id} property={property}/>
        })

        return (
            <section className='featured-property'>
                
                <Title className='title' title="Propiedades Destacadas"/>
                
                    <div className='featured-property-center'>
                        {loading ? <Loading /> : properties}
                    </div>
            </section>
        )
    }
}
