import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

function InfoSection({heading, subtitle, heading2, paragraphOne, paragraphTwo, paragraphTree, reverse, image }) {
    return (
        <Section>
            <Container>
            <Fade bottom> 
                <ColumnLeft>
                <h1>{heading}</h1>
                <p>{subtitle}</p>
                <h3>{heading2}</h3>
                <p>{paragraphOne}</p>
                <p>{paragraphTwo}</p>
                <p>{paragraphTree}</p>
                <Link to='/propiedades' className="btn-primary" >
                    Ver Propiedades
                </Link>
                </ColumnLeft>
            </Fade>
            <Fade right> 
                <ColumnRight reverse={reverse}>
                <img src={image} alt="casa"/>
                </ColumnRight>
            </Fade>
            </Container>
        </Section>
    )
}

export default InfoSection;

const Section= styled.section`
width: 100%;
height: 100%;
padding:4rem 0rem;
`;

const Container= styled.div`
padding: 3rem calc((100vw - 1300px) /2);
display: grid;
grid-template-columns: 1fr 1fr;
grid-template-rows: 800px;

@media screen and (max-width:768px){
    grid-template-columns: 1fr;
}
`;
const ColumnLeft= styled.div`
display: flex;
color: #4F4F50;
flex-direction:column;
justify-content: center;
align-items: flex-start;
text-align: justify;
line-height:1.4;
padding: 1rem 2rem;
order: ${({reverse}) => (reverse ? '2' : '1')};

h1{
    margin-bottom: 1rem;
    font-size: clamp(1.5rem 6vw, 2rem);
    text-align: left;
}
h3{
    margin-bottom: 1rem;
    font-size: clamp(0.7rem 6vw, 1rem);
    text-align: left;
}
p{
    margin-bottom: 2rem;
}
@media screen and (min-width:0px)and (max-width: 375px){
h1{
    font-size: 1.8rem;
}
p{
    font-size: 1rem;
}

}
`;
const ColumnRight= styled.div`
padding: 1rem 2rem;
order: ${({reverse}) => (reverse ? '1' : '2')};
display: flex;
justify-content:center;
align-items: center;

@media screen and (max-width:768px){
    order: ${({reverse}) => (reverse ? '2' : '1')};
    top:0;
}

img{
    width: 100%;
    height: 100%;
    object-fit:cover;
    box-shadow: 0 2px 8px 0 #4F4F50;

    @media screen and (max-width:768px){
        width: 90%;
        height: 90%;

    }
    @media screen and (min-width:0px)and (max-width: 375px){
        margin-top: 8rem;
    }
}
`;

