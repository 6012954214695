import React from 'react';
import {Link} from 'react-router-dom';
import Hero from '../components/Hero';
import Banner from '../components/Banner';
import PropContainer from '../components/PropContainer';

function Propiedades() {
    return (
        <div>
    <Hero hero='propertyHero'>
        <Banner 
            title="CENTANNI ROMINA CELESTE" 
            title1="Matriculas" 
            subtitle1="N° 6147 CUCICBA - CABA"
            subtitle2="N° 6312 CMCPSI - SAN ISIDRO"
            title2='Nuestras Propiedades' 
            subtitle="Casa nueva, vida nueva" >
            <Link to='/' className="btn-primary" style={{marginBottom: '4rem'}}>
                VOLVER A INICIO
            </Link>
        </Banner>
    </Hero>
    <PropContainer/>
    </div>
    )
}

export default Propiedades;
