import React from 'react';
import {useContext} from 'react';
import {PropContext} from '../context';
import Title from '../components/Title';

//get all unique values
const getUnique = (items,value) => {
    return [...new Set(items.map(item => item[value]))]
    }

export default function FiltroPropiedades({properties}){
    const context = useContext(PropContext);
    const {
        handleChange,
        type,
        capacity,
        bathroom,
        zone,
        category,
        currency,
        price,
        pricerent,
        minPrice,
        maxPrice, 
        minPriceRent,
        maxPriceRent,
        minSize, 
        maxSize, 
        parking, 
        pets,
        bb,
    } = context;

    //get unique types
    let types = getUnique(properties,'type');
    // add all
    types = ['Todas' ,...types];
    //map to jsx
    types = types.map((item,index) => {
    return <option value={item} key={index} >{item}</option>
    });

    //Rooms quantity
    let capacities = getUnique(properties, 'capacity');
    capacities = capacities.map((item, index) => {
    return <option key={index} value={item}>
    {item}
    </option>
    });
    
    //Bathrooms quantity
    let bathrooms = getUnique(properties, 'bathroom');
    bathrooms = bathrooms.map((item, index) => {
    return <option key={index} value={item}>
    {item}
    </option>
    });

    //Zone
    let places = getUnique(properties, 'zone');
    places = ['Todas' ,...places];
    places = places.map((item, index) => {
    return <option key={index} value={item}>
    {item}
    </option>
    });

    //Category Building
    let categories = getUnique(properties, 'category');
    categories = ['Todas' ,...categories];
    categories = categories.map((item, index) => {
    return <option key={index} value={item}>
    {item}
    </option>
    });

    //Category Building
    let currencies = getUnique(properties, 'currency');
    currencies = ['Todas' ,...currencies];
    currencies = currencies.map((item, index) => {
    return <option key={index} value={item}>
    {item}
    </option>
    });

    return(
    <section className="filter-container">
        <Title title="Buscar Propiedades" className="filterTitle"/>
            <div className="banner-div"></div>
            <form className="filter-form">
                {/*Filter Operacion*/}
                <div className="form-group-filter">
                <label htmlFor="type">Tipo de Operacion</label>
                    <select 
                    name="type" 
                    id="type" 
                    value={type} 
                    className="form-control"
                    onChange={handleChange}
                    >
                    {types}
                    </select>
                </div>
                {/*End Filter Operacion*/}
                {/*Filter Category*/}
                <div className="form-group-filter">
                <label htmlFor="category">Tipo de Propiedad</label>
                    <select 
                    name="category" 
                    id="category" 
                    value={category} 
                    className="form-control"
                    onChange={handleChange}
                    >
                    {categories}
                    </select>
                </div>
                {/*End Filter Category*/}
                {/*Filter Capacity*/}
                <div className="form-group-filter">
                <label htmlFor="capacity">Nº Ambientes</label>
                    <select 
                    name="capacity" 
                    id="capacity" 
                    value={capacity} 
                    className="form-control"
                    onChange={handleChange}
                    >
                    {capacities}
                    </select>
                </div>
                {/*End Filter Capacity*/}
                {/*Bathrooms quantity*/}
                <div className="form-group-filter">
                <label htmlFor="bathroom">Nº Baños</label>
                    <select 
                    name="bathroom" 
                    id="bathroom" 
                    value={bathroom} 
                    className="form-control"
                    onChange={handleChange}
                    >
                    {bathrooms}
                    </select>
                    </div>
                {/*End Bathrooms quantity*/}
                {/*Filter Zone*/}
                <div className="form-group-filter">
                <label htmlFor="zone">Ubicacion</label>
                    <select 
                    name="zone" 
                    id="zone" 
                    value={zone} 
                    className="form-control"
                    onChange={handleChange}
                    >
                    {places}
                    </select>
                </div>
                {/*End Filter Zone*/}
                {/*size*/}
                <div className="form-group-filter">
                <label htmlFor="price">Sup. cubierta </label>
                <div className="size-inputs">
                    <input
                    type="number"
                    name="minSize"
                    id="size"
                    value={minSize}
                    onChange={handleChange}
                    className="size-input"
                    />
                    <input
                    type="number"
                    name="maxSize"
                    id="size"
                    value={maxSize}
                    onChange={handleChange}
                    className="size-input"
                    />
                </div>
                </div>
                {/* end of select type */}
                {/*Filter Currency*/}
                <div className="form-group-filter">
                <label htmlFor="currency">Moneda</label>
                    <select 
                    name="currency" 
                    id="currency" 
                    value={currency} 
                    className="form-control"
                    onChange={handleChange}
                    >
                    {currencies}
                    </select>
                </div>
                {/*End Filter Currency*/}
                {/*price*/}
                <div className="form-group">
                <label htmlFor="price">Precio Venta U$D {price}</label>
                    <input
                    type="range"
                    name="price"
                    min={minPrice}
                    max={maxPrice}
                    id="price"
                    value={price}
                    onChange={handleChange}
                    className="form-control"
                    />
                    <label htmlFor="pricerent">Precio Alquiler $ {pricerent}</label>
                    <input
                    type="range"
                    name="pricerent"
                    min={minPriceRent}
                    max={maxPriceRent}
                    id="pricerent"
                    value={pricerent}
                    onChange={handleChange}
                    className="form-control"
                    />
                </div>
                {/*price*/}
                {/* extras */}
                <div className="form-group-filter">
                <div className="single-extra">
                    <input
                    type="checkbox"
                    name="parking"
                    id="parking"
                    checked={parking}
                    onChange={handleChange}
                    />
                    <label htmlFor="parking">Cochera</label>
                </div>
                <div className="single-extra">
                <input
                type="checkbox"
                name="bb"
                checked={bb}
                onChange={handleChange}
                />
                <label htmlFor="bb">Balcon / Patio</label>
                </div>
                <div className="single-extra">
                <input
                type="checkbox"
                name="pets"
                checked={pets}
                onChange={handleChange}
                />
                <label htmlFor="pets">Mascotas</label>
                </div>
                </div>
                {/* end of extras type */}
            </form>
    </section>
        
    );
}
