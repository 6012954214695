import ImageOne from '../images/infosection1.jpeg';
import ImageTwo from '../images/infosection2.jpeg';


export const infoData = {
    heading: 'CENTANNI ROMINA CELESTE',
    subtitle: 'MATRICULAS: N° 6147 CUCICBA - CABA | N° 6312 CMCPSI - SAN ISIDRO',
    heading2: 'NUOVI PROPIEDADES',
    paragraphOne: 'Desde Nuovi le acercamos las mejores oportunidades para comprar, alquilar, o invertir. Lo acompañamos paso a paso durante el proceso, ofreciendo un asesoramiento integral y cubriendo todos los aspectos de la operación inmobiliaria.',
    paragraphTwo:'Nos diferenciamos por la dedicación que ponemos al evaluar cada consulta recibida. Siendo que cada cliente tiene necesidades y características únicas, creemos que su asesoramiento debe ser de calidad profesional y sumamente personalizado.',
    paragraphTree:'Sabemos la complejidad que conlleva tomar la decisión de cambiar de propiedad, y también sobre las diversas circunstancias que la rodean. Por ello en Nuovi, ponemos a su disposición todo nuestro esfuerzo, profesionalismo y seriedad para que pueda tomar la decisión correcta.',
    buttonLabel: 'Ver Propiedades',
    image: ImageOne,
    reverse: false,
    delay: 100
}
export const infoDataTwo = {
    heading: 'CENTANNI ROMINA CELESTE',
    subtitle: 'MATRICULAS: N°6147 CUCICBA - CABA | N°6312 CMCPSI - SAN ISIDRO',
    heading2: 'NUOVI PROPIEDADES',
    paragraphOne: 'Desde Nuovi le acercamos las mejores oportunidades para comprar, alquilar, o invertir. Lo acompañamos paso a paso durante el proceso, ofreciendo un asesoramiento integral y cubriendo todos los aspectos de la operación inmobiliaria.',
    paragraphTwo:'Nos diferenciamos por la dedicación que ponemos al evaluar cada consulta recibida. Siendo que cada cliente tiene necesidades y características únicas, creemos que su asesoramiento debe ser de calidad profesional y sumamente personalizado.',
    paragraphTree:'Sabemos la complejidad que conlleva tomar la decisión de cambiar de propiedad, y también sobre las diversas circunstancias que la rodean. Por ello en Nuovi, ponemos a su disposición todo nuestro esfuerzo, profesionalismo y seriedad para que pueda tomar la decisión correcta.',
    buttonLabel: 'Ver Propiedades',
    image: ImageTwo,
    reverse: false,
    delay: 300
}
