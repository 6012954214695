import React from 'react';
import Propiedad from './Propiedad'

export default function ListaPropiedades({properties}){
    if(properties.lenght === 0){
        return (
            <div className="empty-search">
                <h3>No tenemos propiedades con los parámetros elegidos</h3>
            </div>
        )
    }
    return (
        <section className="propertylist">
        <div className="propertylist-center">
        {
        properties.map(item => {
        return <Propiedad key={item.id} property={item} />
        })}   
        </div>
    </section>
    );
}