import React from 'react'
import Footer from './footer';
import Icon from './icons';

function FooterContainer() {
    return (
        <Footer>
            <Footer.Wrapper>
            <Footer.Row>
            <Footer.Column>
                <Footer.Title>Contacto</Footer.Title>
                    <Footer.Link href="#"><Icon className="fas fa-phone" />11 2150-6163</Footer.Link>
                    <Footer.Link href="#"><Icon className="fas fa-envelope-open-text"/>info@nuovi.com.ar</Footer.Link>
                    <Footer.Link href="#"><Icon className="fas fa-map-marker"/>Av Córdoba 1237 <br/>Piso nº 4º - Bs.As. - Arg.</Footer.Link>
                </Footer.Column>
                
                <Footer.Column>
                <Footer.Title>Redes</Footer.Title>
                    <Footer.Link href="https://walink.co/ac4959"  target="new"><Icon className="fab fa-whatsapp"/>Whatsapp</Footer.Link>
                    <Footer.Link href="https://www.instagram.com/nuovipropiedades/?hl=es-la" target="new"><Icon className="fab fa-instagram" />Instagram</Footer.Link>
                    <Footer.Link href="https://www.facebook.com/nuovipropiedades/" target="new"><Icon className="fab fa-facebook-f" />Facebook</Footer.Link>
                </Footer.Column>
                <Footer.Column>
                <Footer.Title>Servicios</Footer.Title>
                    <Footer.Link href="#">Asesoramiento</Footer.Link>
                    <Footer.Link href="#">Tasaciones</Footer.Link>
                    <Footer.Link href="#">Servicio Integral</Footer.Link>
                    
                </Footer.Column>
                <Footer.Column>
                <Footer.Title>About Us</Footer.Title>
                    <Footer.Link href="#">Historia</Footer.Link>
                    <Footer.Link href="#">Clientes</Footer.Link>
                    <Footer.Link href="#">Testimonios</Footer.Link>
                </Footer.Column>
            </Footer.Row>
            
            <div style={{color:"#3CDBC0", textAlign:"center", paddingTop:  "25px", fontSize:"19px"}}>
                <p style={{color: "whitesmoke", fontSize: "1.2rem"}}>CENTANNI ROMINA CELESTE</p> 
                <p style={{color: "whitesmoke", fontSize: "0.9rem"}}>Matriculas: N° 6147 CUCICBA - CABA | N° 6312 CMCPSI - SAN ISIDRO</p>
                <p style={{fontSize: "1rem"}}>2021 ©Nuovi Propiedades | Todos los Derechos Reservados.</p> 
                
                <Footer.Link href="https://agustina-portfolio.netlify.app/" 
                target="new" style={{fontSize: "1rem"}}>Desarrollo Web | Agustina Prats
                </Footer.Link>
            </div>
            
            </Footer.Wrapper>
        </Footer>
    )
}

export default FooterContainer;

