import React from 'react';
import FiltroPropiedades from "./FiltroPropiedades";
import ListaPropiedades from "./ListaPropiedades";
import { withPropConsumer } from "../context";
import Loading from "./Loading";

function PropContainer({context}) {
    const { loading, sortedProperties, properties } = context;
    if (loading) {
      return <Loading />;
    }
    return (
      <div>
      <FiltroPropiedades properties={properties} />
      <ListaPropiedades properties={sortedProperties} />
      </div>
    );
  }
  
  export default withPropConsumer(PropContainer);
