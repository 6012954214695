import React, { Component } from 'react';
import Client from './Contentful';

const PropContext = React.createContext();

export default class PropProvider extends Component {

    state={
        properties:[],
        sortedProperties:[],
        featuredProperties:[],
        loading: true,
        type:'Todas',
        capacity:1,
        bathroom:1,
        zone:'Todas',
        category:'Todas',
        currency:'Todas',
        price:0,
        minPrice:0,
        maxPrice:0,
        pricerent:0,
        minPriceRent:0,
        maxPriceRent:0,
        minSize:0,
        maxSize:0,
        parking:false,
        pets:false,
        bb:false
    }
getData = async () => {
    try {
        let response = await Client.getEntries({
        content_type:"nuovi",
        order: "fields.type"
        });
        let properties = this.formatData(response.items);
        let featuredProperties = properties.filter(property => property.featured === true);
        let maxPrice = Math.max(...properties.map(item => item.price));
        let maxPriceRent = Math.max(...properties.map(item => item.pricerent));
        let maxSize = Math.max(...properties.map(item => item.size));
        this.setState({
        properties,
        featuredProperties,
        sortedProperties:properties, 
        loading:false,
        price: maxPrice,
        maxPrice,
        maxSize,
        pricerent: maxPriceRent,
        maxPriceRent
    })
    }catch(error){
        console.log(error)
    }
}
componentDidMount(){
    this.getData()
}

formatData(items){
    let tempItems = items.map(item => {
        let id = item.sys.id
        let images = item.fields.images.map(image => 
            image.fields.file.url);

        let property = {...item.fields,images,id}
        return property
    });
    return tempItems;
}

getPropiedad = (slug) => {
    let tempProperties = [...this.state.properties];
    const propiedad = tempProperties.find((property) => property.slug === slug)
    return propiedad;
};

handleChange = event => {
    const target = event.target
    const value = target.type === 'checkbox' ?
    target.checked:target.value
    const name = event.target.name;
    this.setState(
        {
        [name]:value
        },this.filterProperties)
}

filterProperties = () => {
    let{ properties, type, capacity, bathroom, zone, category,  price, currency, pricerent, minSize, maxSize, parking, pets, bb} = this.state
    let tempProperties = [...properties];
    
    //transform value
    capacity = parseInt(capacity)
    bathroom = parseInt(bathroom)
    price = parseInt(price)
    pricerent = parseInt(pricerent)

    //Filter by type
    if(type !== 'Todas'){
        tempProperties = tempProperties.filter(property => property.type === type)
    }

    //Filter by capacity
    if(capacity !== 1){
        tempProperties = tempProperties.filter(property => property.capacity === capacity)
    }
     //Filter by bathroom quantity
    if(bathroom !== 1){
        tempProperties = tempProperties.filter(property => property.bathroom >= bathroom)
    }
    //Filter by currency
    if(currency !== 'Todas' ){
        tempProperties = tempProperties.filter(property => property.currency === currency)
    }

    //Filter by price
    tempProperties = tempProperties.filter(property => property.price <= price )

    //Filter by price rent
    tempProperties = tempProperties.filter(property => property.pricerent <= pricerent )

    //filter by size
    tempProperties = tempProperties.filter(
        property => property.size >= minSize && property.size <= maxSize
    );

    //Filter by zone
    if(zone !== 'Todas' ){
        tempProperties = tempProperties.filter(property => property.zone === zone)
    }

    //Filter by property category
    if(category !== 'Todas' ){
        tempProperties = tempProperties.filter(property => property.category === category)
    }

      //filter by parking
    if (parking) {
        tempProperties = tempProperties.filter(property => property.parking === true);
    }
      //filter by pets
    if (pets) {
        tempProperties = tempProperties.filter(property => property.pets === true);
    }
      //filter by balcony/backyard
    if (bb) {
        tempProperties = tempProperties.filter(property => property.bb === true);
    }
    //Change state
    this.setState({
        sortedProperties:tempProperties})
};

    render(){
        return (
        <PropContext.Provider 
        value={{...this.state,
        getPropiedad: this.getPropiedad,
        handleChange: this.handleChange}}>
        {this.props.children}
        </PropContext.Provider>
        );
    }
}

const PropConsumer = PropContext.Consumer;

export function withPropConsumer(Component){
    return function ConsumerWrapper(props){
        return <PropConsumer>
            {value => <Component {...props} context={value}/>}
            </PropConsumer>
    }
}

export {PropProvider, PropConsumer, PropContext};

