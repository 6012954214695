import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Home from './pages/Home';
import Propiedades from './pages/Propiedades';
import Propiedad from './pages/Propiedad';
import Error from './pages/Error';
import Navbar from './components/Navbar';
import Contacto from './pages/Contacto';
import MensajeContacto from './pages/MensajeContacto';
import About from './pages/About';
import ScrollToTop from './components/ScrollToTop';
import Whatsapp from './components/Whatsapp';
import FooterContainer from './components/FooterContainer';
import './App.css';


function App() {
  return (
    <div>
     <ScrollToTop />
      <Navbar/>
       <Switch>
        <Route exact path="/" component={Home}/>
        <Route exact path="/propiedades" component={Propiedades}/>
        <Route exact path="/propiedades/:slug" component={Propiedad} />
        <Route exact path="/contacto" component={Contacto}/>
        <Route exact path="/about" component={About}/>
        <Route exact path="/mensaje" component={MensajeContacto}/>
        <Route component={Error}/>
       </Switch>
       <Whatsapp/>
       <FooterContainer/>
    </div>
  )
}

export default App;



