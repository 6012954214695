import React from 'react';
import {Link} from 'react-router-dom';
import Hero from '../components/Hero';
import logo from "../images/logo.png";
import Fade from 'react-reveal/Fade';
import {useForm, ValidationError} from "@formspree/react";

function Contacto() {
    const [state, handleSubmit] = useForm("contactMe");
        if (state.succeeded){
        window.location.href = '/mensaje'
        }
    return (
        <div>
        <Hero>
        <div className="stylesContainer">
        <Fade bottom cascade>
            <div id="contact-container">
                <div className="contact-info">
                    <h4>CENTANNI ROMINA CELESTE</h4>  
                    <Link to='/' >
                        <img src={logo} alt="Nuovi Propiedades" style={{height: '40px'}}/>
                    </Link>
                    <div className="icon-text">
                        <i className="fas fa-phone" aria-hidden="true"></i>
                        <span>+54911 21506163</span>
                    </div>
                    <div className="icon-text">
                        <i className="fas fa-envelope-open-text" aria-hidden="true"></i>
                        <span>info@nuovi.com.ar</span>
                    </div>
                    <div className="icon-text">
                        <div className="whatsapp">
                        <i className="fab fa-whatsapp" aria-hidden="true"></i>
                        <a href="https://walink.co/ac4959" target="_blank" rel="noopener noreferrer"><span>Enviar Whatsapp</span></a>
                        </div>
                    </div>
                    <div className="icon-text">
                        <i className="fas fa-map-marker" aria-hidden="true"></i>
                        <span>Avenida Córdoba<br/>N º 1237 piso 4º<br/>CABA - Argentina</span>
                    </div>
                </div>

                <form onSubmit={handleSubmit} className="form">
                    <div className="col">
                    <div className="form-group">
                        <label htmlFor="name">Nombre</label>
                        <input type="text" name="name" id="name"
                        required="required"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="lastname">Apellido</label>
                        <input type="text" name="lastname" id="lastname"
                        required="required"/>
                    </div>
                    </div>
                    <div className="col">
                    <div className="form-group">
                        <label htmlFor="email">E-Mail</label>
                        <input type="email" name="email" id="email"
                        required="required"/>
                        <ValidationError field="email" prefix="Email" errors={state.errors}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="tel">Teléfono</label>
                        <input type="tel"
                        name="tel" id="tel"
                        placeholder="(Ej.+54 9 11 0000 0000)"
                        required="required" 
                        />
                    </div>
                    </div>
                    <div className="operacion">
                    <div className="col">
                    <div className="form-group">
                        <label className="operacion2">Operación de Interes</label>
                        <div id="radio-buttons">
                        <div className="radio-button">
                            <input type="radio" id="radioalquiler" name="type" value="alquiler"/><label htmlFor="radioalquiler">Alquiler</label>
                        </div>
                        <div className="radio-button">
                            <input type="radio" id="radioalquilertemporario" name="type" value="alquiler temporario"/><label htmlFor="radioalquilertemporario">Alquiler Temporario</label>
                        </div>
                        <div className="radio-button">
                            <input type="radio" id="radiocompra-venta" name="type" value="compra-venta"/><label htmlFor="radiocompra-venta">Compra-Venta</label>
                        </div>
                        <div className="radio-button">
                            <input type="radio" id="radioemprendimiento" name="type" value="emprendimiento"/><label htmlFor="radioemprendimiento">Emprendimiento</label>
                        </div>
                        <div className="radio-button">
                            <input type="radio" id="radiootro" name="type" value="otro"/>
                            <label htmlFor="radiootro">Otro</label>
                        </div>
                        </div>
                    </div>
                    </div>
                    </div>
                    <div className="col">
                    <div className="form-group">
                        <label htmlFor="message" className="mensaje">Mensaje</label>
                        <textarea name="message" id="message"></textarea>
                    </div>
                    </div>
                    <div className="col">
                    <div className="form-group">
                        <button type="submit" value="Enviar Mensaje"className="primary">ENVIAR</button>
                        <ValidationError errors={state.errors}/>
                    </div>
                    </div>
                </form>
            </div>
            </Fade>
        </div>
        </Hero>
    </div>
    )
}

export default Contacto;



