import React from 'react';
import {Link} from 'react-router-dom';
import Hero from '../components/Hero';
import Banner from '../components/Banner';
import Services from '../components/Services';
import InfoSection from '../components/InfoSection';
import FeaturedProps from '../components/FeaturedProps';
import { infoData} from '../data/InfoData';


const home = () =>  {
    return (
    <div>
    <Hero>
        <Banner 
            title="CENTANNI ROMINA CELESTE" 
            title1="Matriculas" 
            subtitle1="N° 6147 CUCICBA - CABA"
            subtitle2="N° 6312 CMCPSI - SAN ISIDRO"
            title2="NUOVI PROPIEDADES" 
            subtitle="Casa nueva, Vida nueva">
            <Link to='/propiedades' className='btn-primary' style={{marginBottom: '3rem'}}>
                Ver Propiedades
            </Link>
        </Banner>
        
        </Hero>
        <Services/>
        <FeaturedProps/>
        <InfoSection
        {...infoData}/>
    </div>
    );
}
export default home;

