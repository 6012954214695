import React, { Component } from 'react';
import { FaRegBuilding, FaGavel, FaPenFancy } from 'react-icons/fa';
import Fade from 'react-reveal/Fade';
import Title from './Title';

export default class Services extends Component {
state={
    services:[
        {
            icon:<FaPenFancy/>,
            title:"Asesoramiento",
            info:'Atención profesional personalizada y calificada'
        },
        {
            icon:<FaGavel/>,
            title:"Tasaciones",
            info:'Tiempo de espera reducido para tasar.'
        },
        {
            icon:<FaRegBuilding/>,
            title:"Servicio Integral",
            info:'Acompañamiento durante toda la operación inmobiliaria.'
        }
    ]
};
render(){
    return (
        <section className="services">
            <Fade left cascade> 
            <Title title="NUESTROS SERVICIOS" />
            <div className="services-center">
                {this.state.services.map((item, index) => {
                return <article key={index} className="service">
                            <span>{item.icon}</span>
                            <h6>{item.title}</h6>
                            <p>{item.info}</p>
                        </article>
                })}
            </div>
            </Fade>
        </section>
    );
}
}