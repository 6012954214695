import React from 'react';
import Fade from 'react-reveal/Fade';
import styled from 'styled-components';


export default function Whatsapp() {

function clickMe(){
    alert('Redireccionando a Whatsapp')
}
    return (
        <div>
        <Fade left cascade> 
        <div>
        <a href="https://walink.co/ac4959" target="_blank" rel="noopener noreferrer">
            <Button onClick={clickMe}>
            <i className="fab fa-whatsapp" aria-hidden="true"></i>
            </Button>
            </a>
        </div>
        </Fade>
        </div>
    )
}

const Button = styled.button`
outline:0;
border-radius: 50px;
box-shadow: var(--darkShadow);
background-color: #3CDBC0;
justify-content:center;
align-items:center;
text-align:center;
position: fixed;
bottom: 20px;
right: 30px;
height: 65px;
width: 65px;
font-size:3rem;
padding-top:2.5px;
padding-left:7.5px;
color: #4F4F50;
border:4px solid white;
transition: ease background-color 250ms;
cursor:pointer;
z-index:100;

@media screen and (min-width: 320px)and (max-width: 768px){
bottom: 25px;
right: 25px;
}
&:hover{
background-color: #ffffff; 
box-shadow: var(--darkShadow);
transform: scale(1.07);
transition-duration: 1s;
transition-timing-function: ease-out;
}
`