import React,  { Component, Fragment } from 'react'
import {Link} from 'react-router-dom';
import defaultImg from '../images/prop-1.jpg';
import Banner from '../components/Banner';
import { PropContext } from "../context";
import StyledHero from "../components/StyledHero";

export default class Propiedad extends Component{
    constructor(props){
        super(props);
        this.state = {
        slug: this.props.match.params.slug, 
        defaultBcg: defaultImg
    };
}
static contextType= PropContext;
    //componentDidMount(){}
    render(){
        const {getPropiedad} = this.context;
        const property = getPropiedad(this.state.slug);
        if(!property){
            return (
            <div className="error">
                <h3>No property could be found ...</h3>
                <Link to="/propiedades" className="btn-primary" >
                    Volver a Propiedades
                </Link>
            </div>
            )
        }
    const {name, category ,description, description1, description2, description3, description4, description5, disclaimer, price, pricerent, expenses,bathroom, rooms, toilettes, years, ref,  size, totalSize, extras,bb, parking, images, } = property;

    const [...defaultImages] = images;

    return (
        <Fragment>
        <StyledHero img={images[0] || this.state.defaultBcg}>
            <Banner title={`${name}`}>
            <Link to="/propiedades" className="btn-primary">
              Volver a Propiedades
            </Link>
            </Banner>
        </StyledHero>

        <section className="single-property">
            <div className="single-property-images">
            {defaultImages.map((item, index) => (
              <img src={item} key={index} alt={name} className="singleTop"/>
            ))}
            </div>
       
          <div className="single-property-info">
            <article className="desc">
              <h3>Descripción</h3>
              <h4>{description}</h4>
              <p>{description1}</p>
              <p>{description2}</p>
              <p>{description3}</p>
              <p>{description4}</p>
              <p>{description5}</p>
              <p className="disclaimer">{disclaimer}</p>
            </article>
            <article className="info">
              <h3>Info</h3>
              <h6>Ref: {ref}</h6>
              <h6>Precio Venta: U$D {price}</h6>
              <h6>Precio Alquiler: ${pricerent}</h6>
              <h6>Precio Expensas: ${expenses}</h6>
              <h6>Categoria: {category}</h6>
              <h6>Sup. Cubierta: {size} Mts.</h6>
              <h6>Sup. Total: {totalSize} Mts.</h6>  
              <h6>Habitaciones: {rooms} </h6>
              <h6>Baños: {bathroom}</h6>
              <h6>Toilettes: {toilettes}</h6>
              <h6>Antiguedad: {years} años</h6>
              <h6>{bb ? "Balcón/Patio: SI" : "Balcón/Patio: NO"}</h6>
              <h6>{parking ? "Cochera: SI" : "Cochera: NO"}</h6>
              
            </article>
          </div>
        </section>
        <section className="property-extras">
          <h6>Información Adicional: </h6>
          <ul className="extras">
            {extras.map((item, index) => (
              <li key={index}>- {item}</li>
            ))}
          </ul>
        </section>
    </Fragment>
    )
  }
}
