import React from 'react';
import Hero from '../components/Hero';
import Banner from '../components/Banner';
import {Link} from 'react-router-dom';

export default function MensajeContacto() {
    return <Hero>
                <Banner title='Gracias por su consulta!!!' 
                    subtitle='Responderemos a la brevedad'>
                <Link to='/' className="btn-primary">
                    Volver a Inicio
                </Link>
                </Banner>
            </Hero>
}