import React from 'react';
import {Link} from 'react-router-dom';
import Banner from '../components/Banner';
import Hero from '../components/Hero';
import InfoSection from '../components/InfoSection';
import { infoDataTwo } from '../data/InfoData';

export default function About(){
    return (
        <div>
        <Hero>
            <Banner 
                title="CENTANNI ROMINA CELESTE" 
                title1="Matriculas" 
                subtitle1="N° 6147 CUCICBA - CABA"
                subtitle2="N° 6312 CMCPSI - SAN ISIDRO" 
                title2="NUOVI PROPIEDADES" 
                subtitle="Casa nueva, vida nueva">
                <Link to='/propiedades' className='btn-primary' style={{marginBottom: '3rem'}}>
                    Ver Propiedades
                </Link>
            </Banner>
        </Hero>
            <InfoSection {...infoDataTwo}/>
    </div>
    )
}